export const GET_USER_ACTIVITIES_REQUEST = "GET_USER_ACTIVITIES_REQUEST";
export const GET_USER_ACTIVITIES_SUCCESS = "GET_USER_ACTIVITIES_SUCCESS";
export const GET_USER_ACTIVITIES_FAILURE = "GET_USER_ACTIVITIES_FAILURE";

export const GET_USER_COMMENTS_REQUEST = "GET_USER_COMMENTS_REQUEST";
export const GET_USER_COMMENTS_SUCCESS = "GET_USER_COMMENTS_SUCCESS";
export const GET_USER_COMMENTS_FAILURE = "GET_USER_COMMENTS_FAILURE";

export const RESET_USER_DATA = "RESET_USER_DATA";
export const IS_LOADING_SCREEN = "IS_LOADING_SCREEN";
