export const ADD_LESSON_PROCESS = "ADD_LESSON_PROCESS";
export const ADD_MINUTE_FOR_ACTION = "ADD_MINUTE_FOR_ACTION";
export const SET_SELECTED_ACTION = "SET_SELECTED_ACTION";

export const CREATE_NEW_LESS_PROC_SUCCESS = "CREATE_NEW_LESS_PROC_SUCCESS";
export const CREATE_NEW_LESS_PROC_FAILURE = "CREATE_NEW_LESS_PROC_FAILURE";

export const FETCH_CURRENT_IMAGE_PATH_SUCCESS =
  "FETCH_CURRENT_IMAGE_PATH_SUCCESS";
export const FETCH_CURRENT_IMAGE_PATH_FAILURE =
  "FETCH_CURRENT_IMAGE_PATH_SUCCESS_FAILURE";

export const FETCH_CURRENT_FILE_PATH_SUCCESS =
  "FETCH_CURRENT_FILE_PATH_SUCCESS";
export const FETCH_CURRENT_FILE_PATH_FAILURE =
  "FETCH_CURRENT_FILE_PATH_FAILURE";

export const LESSON_PROCESS_COUNT = "LESSON_PROCESS_COUNT";

export const LESSON_PROCESS_ID = "LESSON_PROCESS_ID";
export const LESSON_PROCESS_ID_AND_TYPE = "LESSON_PROCESS_ID_AND_TYPE";

export const FETCH_USER_METHOD_REQUEST = "FETCH_USER_METHOD_REQUEST";
export const FETCH_USER_METHOD_SUCCESS = "FETCH_USER_METHOD_SUCCESS";
export const FETCH_USER_METHOD_FAILURE = "FETCH_USER_METHOD_FAILURE";
export const DELETE_LESSON_PROCESS = "DELETE_LESSON_PROCESS";

export const FETCH_METHOD_DATA_REQUEST = "FETCH_METHOD_DATA_REQUEST";
export const FETCH_METHOD_DATA_SUCCESS = "FETCH_METHOD_DATA_SUCCESS";
export const FETCH_METHOD_DATA_FAILURE = "FETCH_METHOD_DATA_FAILURE";

export const FETCH_METHOD_DETAILS_REQUEST = "FETCH_METHOD_DETAILS_REQUEST";
export const FETCH_METHOD_DETAILS_SUCCESS = "FETCH_METHOD_DETAILS_SUCCESS";
export const FETCH_METHOD_DETAILS_FAILURE = "FETCH_METHOD_DETAILS_FAILURE";

export const ADD_NEW_LESSON_PROCESS = "ADD_NEW_LESSON_PROCESS";

export const ADD_METHOD_NAME = "ADD_METHOD_NAME";
export const PUSH_ADDED_METHOD = "PUSH_ADDED_METHOD";
export const CREATE_METHOD_DETAILS_SUCCESS = "CREATE_METHOD_DETAILS_SUCCESS";
export const REPLACE_METHOD = "REPLACE_METHOD";
export const REMOVE_METHOD = "REMOVE_METHOD";
export const REMOVE_ACTION_BY_INDEX = "REMOVE_ACTION_BY_INDEX";

export const CLEAN_PROCESS_REDUCER = "CLEAN_PROCESS_REDUCER";
export const OPEN_CONFIRM_ACTION = "OPEN_CONFIRM_ACTION";
export const ACTION_SAVED_VALUE = "ACTION_SAVED_VALUE";
export const METHOD_AND_ACTION_INDEX = "METHOD_AND_ACTION_INDEX";
export const CHECK_MAIN_CONTAINER_INFO = "CHECK_MAIN_CONTAINER_INFO";
export const DELETE_OR_RECOVER_ACTION_IF_NEXT = "DELETE_OR_RECOVER_ACTION_IF_NEXT";
export const ORDER_AND_PREVIOUS_ORDER = "ORDER_AND_PREVIOUS_ORDER";
export const ACTION_AND_METHOD_ID = "ACTION_AND_METHOD_ID";

export const RIGHT_SIDEBAR_IMAGES = "RIGHT_SIDEBAR_IMAGES";
export const DELETE_BOARD_IMAGE = "DELETE_BOARD_IMAGE";

export const PDF_FILE_ARR = "PDF_FILE_ARR";

export const HANDLE_CHANGE_LSN_PROC = "HANDLE_CHANGE_LSN_PROC";

export const CREATE_LSN_METHOD_REQUEST = "CREATE_LSN_METHOD_REQUEST";
export const CREATE_LSN_METHOD_SUCCESS = "CREATE_LSN_METHOD_SUCCESS";
export const CREATE_LSN_METHOD_FAILURE = "CREATE_LSN_METHOD_FAILURE";

export const DELETE_METHOD_REQUEST = "DELETE_METHOD_REQUEST";
export const DELETE_METHOD_SUCCESS = "DELETE_METHOD_SUCCESS";
export const DELETE_METHOD_FAILURE = "DELETE_METHOD_FAILURE";

export const EDIT_METHOD_REQUEST = "EDIT_METHOD_REQUEST";
export const EDIT_METHOD_SUCCESS = "EDIT_METHOD_SUCCESS";
export const EDIT_METHOD_FAILURE = "EDIT_METHOD_FAILURE";

export const PATH_OF_CURRENT_IMAGE_REQUEST = "PATH_OF_CURRENT_IMAGE_REQUEST";
export const PATH_OF_CURRENT_IMAGE_SUCCESS = "PATH_OF_CURRENT_IMAGE_SUCCESS";
export const PATH_OF_CURRENT_IMAGE_FAILURE = "PATH_OF_CURRENT_IMAGE_FAILURE";

export const RIGHT_SIDEBAR_FIRST_PAGE = "RIGHT_SIDEBAR_FIRST_PAGE";
