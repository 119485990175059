export const FETCH_REGIONS_FAILURE = "FETCH_REGIONS_FAILURE";
export const FETCH_REGIONS_REQUEST = "FETCH_REGIONS_REQUEST";
export const FETCH_REGIONS_SUCCESS = "FETCH_REGIONS_SUCCESS";

export const FETCH_COMMUNITIES_BY_REGION_REQUEST =
  "FETCH_COMMUNITIES_BY_REGION_REQUEST";
export const FETCH_COMMUNITIES_BY_REGION_SUCCESS =
  "FETCH_COMMUNITIES_BY_REGION_SUCCESS";
export const FETCH_COMMUNITIES_BY_REGION_FAILURE =
  "FETCH_COMMUNITIES_BY_REGION_FAILURE";

export const FETCH_SCHOOLS_BY_COMMUNITY_REQUEST =
  "FETCH_SCHOOLS_BY_COMMUNITY_REQUEST";
export const FETCH_SCHOOLS_BY_COMMUNITY_SUCCESS =
  "FETCH_SCHOOLS_BY_COMMUNITY_SUCCESS";
export const FETCH_SCHOOLS_BY_COMMUNITY_FAILURE =
  "FETCH_SCHOOLS_BY_COMMUNITY_FAILURE";
