import "../studentsLessonPlan.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import timer from "../../../img/timer.svg";
import { Rating } from "./Rating";
import { Alert } from "../../../components";
import blackCrow from "../../../img/blackCrow.svg";

export const ProgressBar = ({ lessonPlanData }) => {
  const [progress, setProgress] = useState(0);
  const [openrate, setOpenrate] = useState(false);
  const [step, setStep] = useState(1);

  const allActions = useMemo(() => {
    let newActions = [];
    
    for (let i = 0; i < lessonPlanData?.process?.length; i++) {
      newActions.push(...lessonPlanData?.process[i].actions);
    }
    
    const filterActions = newActions?.filter(el => {
      return el.studentActionDesc || el.studentAction || el.pupilWorkPart;
    });
    
    return filterActions;
  }, [lessonPlanData.process]);

  const [currentData, setCurrentData] = useState(allActions[step - 1]);

  const tiltle = lessonPlanData?.lessonPlanTopic;

  const progressPercent = allActions.length;
  const progressLength = 100 / (progressPercent || 0);

  useEffect(() => {
    setProgress(progressLength);
  }, [progressLength]);

  useEffect(() => {
    setCurrentData(allActions[step - 1]);
  }, [step, allActions?.length])

  const handleNextClick = () => {
    window.scrollTo(0, 0);

    setProgress(prev => prev + progressLength);
    setStep(prev => prev + 1);
  };

  const handleBackClick = () => {
    window.scrollTo(0, 0);
 
    if (progress > progressLength) {
      setProgress(progress - progressLength);
     
      setStep(prev => prev - 1);
    }
  };

  const handleOpenRating = () => {
    setOpenrate(true);
  };

  const handleCloseRate = () => {
    setOpenrate(false);
  };  
  
  return (
    <>
      <div className="progress-container">
        <div className="responsive-content">
          <div className="progress-wrapper">
            <div
              className="progress-bar"
              style={{
                width: `${Math.round(progress)}%`,
              }}
            ></div>
          </div>
          <p className="lp-title">{tiltle}</p>
          <div className="borard-header">
            <div className="alert-button-responsive">
              Քայլ {step} / {allActions?.length}
            </div>
            {currentData?.duration > 1 && (
              <div className="timer-content">
                <img src={timer} className="timer-img" alt="" />
                {currentData.duration} ր
              </div>
            )}
          </div>
          <div>
            {currentData?.files?.procImages?.files?.length > 0 && (
              <p className="board-responsive">Գրատախտակ</p>
            )}
          </div>

          <div>
            {currentData?.files?.procImages?.files?.length > 0 &&
              currentData.files.procImages.files.map((lp, index) => (
                <img
                  alt=""
                  className="board-image-responsive"
                  key={index}
                  src={`${currentData.files.procImages.urlPath}/${lp}`}
                />
              ))}
          </div>
          {currentData?.studentAction && (
            <>
              <div
                className="sections-student-description student-part"
                style={{ marginTop: 16 }}
              >
                <div style={{ display: "flex", width: "100%" }}>
                  <div className="imageDiv">
                    <img src={blackCrow} alt="board" />
                  </div>

                  <div className={"clone-image-div"} />
                  <p className="balckboard">
                    {currentData?.studentAction}
                  </p>
                </div>
              </div>

              <p className="board-text-responsive">
                {currentData?.studentActionDesc}
              </p>
            </>
          )}

          <hr className="board-line-responsive" />

          <div className="board-pupilWorkPart">
            <p dangerouslySetInnerHTML={{__html: currentData?.pupilWorkPart}}/>
          </div>
        </div>

        
        {openrate && (
          <Alert
            onCloseConfirm={handleCloseRate}
            openConfirm={true}
            confirmContainerClass="adminPageAlert"
            popUpContainerStyles={{ top: "50%" }}
          >
            <Rating
              lessonPlanData={lessonPlanData}
              handleCloseRate={handleCloseRate}
            />
          </Alert>
        )}
      </div>
      <div className="back-next">
          {step > 1 && (
            <button className="alert-button-confirm previous-button" onClick={handleBackClick}>
              Նախորդ
            </button>
          )}
          {!(step == allActions.length) ? (
            <button className="alert-button-confirm" onClick={handleNextClick}>
              Հաջորդ
            </button>
          ) : (
            <button className="alert-button-confirm" onClick={handleOpenRating}>
              Ավարտ
            </button>
          )}
        </div>
    </>
  );
};
