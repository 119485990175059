export function lessonPlanSidebarData(showSelectedMethods, t) {
  return [
    {
      text: t("Assistants"),
      hash: "teacherId",
      show: true,
      id: 1,
    },
    {
      text: t("Subject/Grade"),
      hash: "subjectAndClassId",
      show: true,
      id: 3,
    },
    {
      text: t("Topic"),
      hash: "topic",
      show: true,
      id: 4,
    },
    {
      text: t("Used materials"),
      hash: "materialsUsed",
      show: true,
      id: 5,
    },
    {
      text: t("Lesson objectives"),
      hash: "lessonObjective",
      show: true,
      id: 6,
    },
    {
      text: t("Lesson extended picture"),
      hash: "completePictureOfClass",
      show: true,
      id: 7,
    },
    {
      text: t("Learning outcomes"),
      hash: "finalResults",
      show: true,
      id: 8,
    },
    // {
    //   text: t("Selected methods"),
    //   hash: "selectedMethods",
    //   show: showSelectedMethods,
    // },
    {
      text: t("Terminology"),
      hash: "terms",
      show: true,
      id: 9,
    },
    {
      text: t("Homework"),
      hash: "homeWork",
      show: true,
      id: 10,
    },
    {
      text: t("Whiteboard content"),
      hash: "blackBoard",
      show: true,
      id: 11,
    },
  ];
}
