export const ON_LOGIN_REQUEST = "ON_LOGIN_REQUEST";
export const ON_LOGIN_SUCCESS = "ON_LOGIN_SUCCESS";
export const ON_LOGIN_FAILURE = "ON_LOGIN_FAILURE";

export const ON_REGISTER_DONE = "ON_REGISTER_DONE";
export const ON_REGISTER_REQUEST = "ON_REGISTER_REQUEST";
export const ON_REGISTER_SUCCESS = "ON_REGISTER_SUCCESS";
export const ON_REGISTER_FAILURE = "ON_REGISTER_FAILURE";

export const SET_USER = "SET_USER";

export const HANDLE_AUTH_CHANGE = "HANDLE_AUTH_CHANGE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const RECOVER_PASSWORD_REQUEST = "RECOVER_PASSWORD_REQUEST";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_FAILURE = "RECOVER_PASSWORD_FAILURE";

export const AUTHORIZE_REQUEST = "AUTHORIZE_REQUEST";
export const AUTHORIZE_SUCCESS = "AUTHORIZE_SUCCESS";
export const EDIT_PROFILE_REQUEST = "EDIT_PROFILE_REQUEST";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAILURE = "EDIT_PROFILE_FAILURE";

export const EDIT_PASSWORD_SUCCESS = "EDIT_PASSWORD_SUCCESS";
export const EDIT_PASSWORD_FAILURE = "EDIT_PASSWORD_FAILURE";

export const FETCH_PROF_DETAILS_FAILURE = "FETCH_PROF_DETAILS_FAILURE";

export const FETCH_NUMBER_CODE_REQUEST = "FETCH_NUMBER_CODE_REQUEST";
export const FETCH_NUMBER_CODE_SUCCESS = "FETCH_NUMBER_CODE_SUCCESS";
export const FETCH_NUMBER_CODE_FAILURE = "FETCH_NUMBER_CODE_FAILURE";

export const LOGOUT_SUCCESS = "USERS_LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "USERS_LOGOUT_FAILURE";
