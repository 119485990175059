export const HANDLE_FORM_CHANGE = "HANDLE_FORM_CHANGE";
export const HANDLE_FORM_ON_CHANGE_ARRAY = "HANDLE_FORM_ON_CHANGE_ARRAY";
export const CLEAN_FROM = "CLEAN_FORM";
export const INIT_FORM = "INIT_FORM";

export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE";

export const INSERT_IMAGE_REQUEST = "INSERT_IMAGE_REQUEST";
export const INSERT_IMAGE_SUCCESS = "INSERT_IMAGE_SUCCESS";
export const INSERT_IMAGE_FAILURE = "INSERT_IMAGE_FAILURE";

export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";
