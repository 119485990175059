import axios from "axios";

export const sendRatingNumber = (lessonPlanId, starsCount) => {
  return (dispatch) => {
    axios
      .post(`/api/lessonPlan/rate`, { lessonPlanId, starsCount })
      .then((data) => {
        if (data.data.success) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error.message);
        throw error.message;
      })
      .finally(() => {
        // dispatch(pendingLessonPlanLink(false));
      });
  };
};
