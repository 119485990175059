import * as React from "react";
import "./SuggestedNotes.css";
import "../Selects/Selects.css";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { useState } from "react";
import { handleFormChange } from "../../../../store";

export const ConfirmLessonPlanFilter = ({ handleCloseFilter }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");

  const ratingSearchValue = useSelector(
    (state) => state.formReducer.ratingSearchValue
  );
  const selectedClass = useSelector((state) => state.formReducer.selectedClass);

  const subjectIdForRate = useSelector(
    (state) => state.formReducer.subjectIdForRate
  );

  const handleCancel = useCallback(
    (e) => {
      e.preventDefault();
      handleCloseFilter();
      dispatch(handleFormChange("ratingSearch", undefined));
      dispatch(handleFormChange("ratingClass", undefined));
      dispatch(handleFormChange("ratingSubject", undefined));
      dispatch(handleFormChange("methodId", undefined));
      dispatch(handleFormChange("selectedMethodinFilterName", undefined));
    },
    [dispatch, handleCloseFilter]
  );

  const handleConfirmFilter = (e) => {
    e.preventDefault();
    dispatch(handleFormChange("ratingSearch", ratingSearchValue));
    dispatch(handleFormChange("ratingClass", selectedClass));
    dispatch(handleFormChange("ratingSubject", subjectIdForRate));
  };

  const handleChangeSearchValue = (e) => {
    const { value } = e?.target;
    setValue(value);
    dispatch(handleFormChange("ratingSearchValue", value));
  };

  return (
    <form
      // onSubmit={handleSearch}
      style={{ position: "relative" }}
    >
      <div className="open-layout-suggested-notes-container">
        <p className="open-layout-suggested-notes-title">Բանալի բառեր</p>
        <input
          className="notes"
          value={value}
          onChange={handleChangeSearchValue}
          placeholder="Օրինակ՝ ֆունկցիա"
          maxLength="10000"
        />
      </div>
      <div style={{ position: "absolute", right: "0", bottom: "0" }}>
        <button onClick={handleCancel} className="cancel_btn">
          Մաքրել ֆիլտրը
        </button>
        <button onClick={handleConfirmFilter} className="save_notes">
          Հաստատել
        </button>
      </div>
    </form>
  );
};
