import { useMemo, useState } from "react";
import "../studentsLessonPlan.css";
import emptyStar from "../../../img/empty-star.svg";
import fullStar from "../../../img/full-star.svg";
import { useDispatch } from "react-redux";
import { sendRatingNumber } from "../../../store/student/actions/sendRating";
import close from "../../../img/closebutton.svg";

export const Rating = ({ lessonPlanData, handleCloseRate }) => {
  const [rating, setRating] = useState(0);
  const stars = [1, 2, 3, 4, 5];
  const [finish, setFinish] = useState("");
  const dispatch = useDispatch();

  let months = {
    "01": "Հունվարի",
    "02": "Փետրվարի",
    "03": "Մարտի",
    "04": "Ապրիլի",
    "05": "Մայիսի",
    "06": "Հունիսի",
    "07": "Հուլիսի",
    "08": "Օգոստոսի",
    "09": "Սեպտեմբերի",
    "10": "Հոկտեմբերի",
    "11": "Նոյեմբերի",
    "12": "Դեկտեմբերի"
  }

  const expireDate = useMemo(() => {
    if(!lessonPlanData?.expireDate) {
      return {days: "", month: ""};
    };

    let splitAllDate = lessonPlanData?.expireDate?.split("-");
    let getDayFromExpireDate = splitAllDate[2]?.split("T");

    if(getDayFromExpireDate[0][0] === "0") {
      getDayFromExpireDate[0] = getDayFromExpireDate[0][1];
    }

    return {day:getDayFromExpireDate[0], month: splitAllDate[1]};

  }, [lessonPlanData?.expireDate]);

  
  const handleClick = (value) => {
    if (finish === "") {
      setRating(value);
    }
  };

  const handleSendRating = () => {
    if (rating !== 0) {
      dispatch(sendRatingNumber(lessonPlanData?.id, rating));
      setTimeout(() => {
        setFinish("Շնորհակալություն");
      }, 500);
    }
  };

  return (
    <div
      className={
        window.innerWidth <= 1120
          ? "raiting-content-responsive"
          : "raiting-content"
      }
    >
      {window.innerWidth <= 1120 && (
        <div className="close-rating">
          <img src={close} alt="close" onClick={handleCloseRate} />
        </div>
      )}
        
      <p className="raiting-text">Խնդրում ենք գնահատել դասի ընթացքը</p>
      {!!lessonPlanData?.expireDate && (
        <p className="available-date">
          Դասը հասանելի կլինի նույն հղումով մինչև {`${months[expireDate.month]} ${expireDate.day}`}-ը ներառյալ։
        </p>
      )}

      <div className="raiting">
        {stars.map((index) => (
          <img
            key={index}
            onClick={() => handleClick(index)}
            className="raiting-star"
            src={index <= rating ? fullStar : emptyStar}
          />
        ))}
      </div>
      <div className="confirm-button">
        {finish === "" ? (
          rating === 0 ? (
            <div
              className="alert-button-confirm-disabled"
              onClick={handleSendRating}
            >
              Հաստատել
            </div>
          ) : (
            <div className="alert-button-confirm" onClick={handleSendRating}>
              Հաստատել
            </div>
          )
        ) : (
          <p className="thank">{finish}</p>
        )}
      </div>
    </div>
  );
};
