export const CREATE_NEW_LESSON_PLAN = "CREATE_NEW_LESSON_PLAN";
export const SHOW_SHORT_DESC = "SHOW_SHORT_DESC";

export const FETCH_METHODS_SUCCESS = "FETCH_METHODS_SUCCESS";
export const FETCH_METHODS_FAILURE = "FETCH_METHODS_FAILURE";

export const CHANGE_METHODS = "CHANGE_METHODS";
export const CHANGE_FILES = "CHANGE_FILES";
export const CHANGE_MINUTE = "CHANGE_MINUTE";

export const CREATE_NEW_LESS_PLAN_SUCCESS = "CREATE_NEW_LESS_PLAN_SUCCESS";
export const CREATE_NEW_LESS_PLAN_FAILURE = "CREATE_NEW_LESS_PLAN_FAILURE";

export const BTN_DISABLE = "BTN_DISABLE";

export const FETCH_SUBJECTS_SUCCESS = "FETCH_SUBJECTS_SUCCESS";
export const FETCH_SUBJECTS_FAILURE = "FETCH_SUBJECTS_FAILURE";

export const FETCH_CLASSES_SUCCESS = "FETCH_CLASSES_SUCCESS";
export const FETCH_CLASSES_FAILURE = "FETCH_CLASSES_FAILURE";

export const FETCH_LSNPLAN_DETAILS_SUCCESS = "FETCH_LSNPLAN_DETAILS_SUCCESS";
export const FETCH_LSNPLAN_DETAILS_FAILURE = "FETCH_LSNPLAN_DETAILS_FAILURE";

export const CHANGE_DESC = "CHANGE_DESC";

export const USER_LESSON_PLANS_REQUEST = "USER_LESSON_PLANS_REQUEST";
export const USER_LESSON_PLANS_SUCCESS = "USER_LESSON_PLANS_SUCCESS";
export const USER_LESSON_PLANS_FAILURE = "USER_LESSON_PLANS_FAILURE";

export const LESSON_PLANS_SUCCESS = "LESSON_PLANS_SUCCESS";
export const LESSON_PLANS_FAILURE = "LESSON_PLANS_FAILURE";

export const USER_DEVELOPED_LESSON_PLANS_SUCCESS =
    "USER_DEVELOPED_LESSON_PLANS_SUCCESS";

export const USER_DEVELOPED_LESSON_PLANS_FAILURE =
    "USER_DEVELOPED_LESSON_PLANS_FAILURE";

export const FETCH_LSNPLAN_DETAILS_FOR_EDIT_SUCCESS =
    "FETCH_LSNPLAN_DETAILS_FOR_EDIT_SUCCESS";

export const FETCH_LSNPLAN_DETAILS_FOR_EDIT_FAILURE =
    "FETCH_LSNPLAN_DETAILS_FOR_EDIT_FAILURE";

export const CAN_EDIT_LESSON_PLAN_SUCCESS = "CAN_EDIT_LESSON_PLAN_SUCCESS";
export const CAN_EDIT_LESSON_PLAN_FAILURE = "CAN_EDIT_LESSON_PLAN_FAILURE";
export const EDIT_LESSON_PLAN_SUCCESS = "EDIT_LESSON_PLAN_SUCCESS";
export const EDIT_LESSON_PLAN_FAILURE = "EDIT_LESSON_PLAN_FAILURE";
export const LESSON_PAN_EXPERTS = "LESSON_PAN_EXPERTS";
export const GET_LESSON_PLAN_PRINT_FILES = "GET_LESSON_PLAN_PRINT_FILES";
export const COPY_FILES_ARR = "COPY_FILES_ARR";
export const HANDLE_LSN_PLAN_AND_LSN_PROC = "HANDLE_LSN_PLAN_AND_LSN_PROC"

export const UPLOAD_LESSON_PLAN_IMAGE_REQUEST = "UPLOAD_LESSON_PLAN_IMAGE_REQUEST"
export const UPLOAD_LESSON_PLAN_IMAGE_SUCCESS = "UPLOAD_LESSON_PLAN_IMAGE_SUCCESS"
export const UPLOAD_LESSON_PLAN_IMAGE_FAILURE = "UPLOAD_LESSON_PLAN_IMAGE_FAILURE"

export const DELETE_LESSON_PLAN_REQUEST = "DELETE_LESSON_PLAN_REQUEST";
export const DELETE_LESSON_PLAN_SUCCESS = "DELETE_LESSON_PLAN_SUCCESS";
export const DELETE_LESSON_PLAN_FAILURE = "DELETE_LESSON_PLAN_FAILURE";
