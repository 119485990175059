export const ADD_COMMENT_REQUEST = "ADD_COMMENT_REQUEST";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_FAILURE = "ADD_COMMENT_FAILURE";

export const GET_COMMENTS_REQUEST = "GET_COMMENTS_REQUEST";
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS";
export const GET_COMMENTS_FAILURE = "GET_COMMENTS_FAILURE";

export const GET_COMMENTS_FROM_NOTIFICATIONS_REQUEST = "GET_COMMENTS_FROM_NOTIFICATIONS_REQUEST";
export const GET_COMMENTS_FROM_NOTIFICATIONS_SUCCESS = "GET_COMMENTS_FROM_NOTIFICATIONS_SUCCESS";
export const GET_COMMENTS_FROM_NOTIFICATIONS_FAILURE = "GET_COMMENTS_FROM_NOTIFICATIONS_FAILURE";

export const GET_COMMENTS_FROM_SIDEBAR_REQUEST =
  "GET_COMMENTS_FROM_SIDEBAR_REQUEST";
export const GET_COMMENTS_FROM_SIDEBAR_SUCCESS =
  "GET_COMMENTS_FROM_SIDEBAR_SUCCESS";
export const GET_COMMENTS_FROM_SIDEBAR_FAILURE =
  "GET_COMMENTS_FROM_SIDEBAR_FAILURE";

export const GET_ALL_COMMENTS_REQUEST = "GET_ALL_COMMENTS_REQUEST";
export const GET_ALL_COMMENTS_SUCCESS = "GET_ALL_COMMENTS_SUCCESS";
export const GET_ALL_COMMENTS_FAILURE = "GET_ALL_COMMENTS_FAILURE";

export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE";

export const UPDATE_COMMENT_REQUEST = "UPDATE_COMMENT_REQUEST";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_FAILURE = "UPDATE_COMMENT_FAILURE";

export const GET_COMMENT_TYPES_REQUEST = "GET_COMMENT_TYPES_REQUEST";
export const GET_COMMENT_TYPES_SUCCESS = "GET_COMMENT_TYPES_SUCCESS";
export const GET_COMMENT_TYPES_FAILURE = "GET_COMMENT_TYPES_FAILURE";

export const GET_COMMENT_TYPES_OF_LOCATION_REQUEST =
  "GET_COMMENT_TYPES_OF_LOCATION_REQUEST";
export const GET_COMMENT_TYPES_OF_LOCATION_SUCCESS =
  "GET_COMMENT_TYPES_OF_LOCATION_SUCCESS";
export const GET_COMMENT_TYPES_OF_LOCATION_FAILURE =
  "GET_COMMENT_TYPES_OF_LOCATION_FAILURE";

export const RESOLVE_COMMENT_REQUEST = "RESOLVE_COMMENT_REQUEST";
export const RESOLVE_COMMENT_SUCCESS = "RESOLVE_COMMENT_SUCCESS";
export const RESOLVE_COMMENT_FAILURE = "RESOLVE_COMMENT_FAILURE";

export const RESET_COMMENTS = "RESET_COMMENTS";
export const CHANGE_COMMENT_PAGE = "CHANGE_COMMENT_PAGE";
export const ADD_REPLY_COMMENT = "ADD_REPLY_COMMENT";
