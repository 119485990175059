import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import "./LessonPlanSidebarItem.css";
import { connect } from "react-redux";
import {
  disableLsnPlanItem,
  fetchLessonPlanDetailsForEdit,
} from "../../../store/lessonPlan/actions";
import { handleFormChange } from "../../../store";

function LessonPlanSidebarItemComponent({
  onClick,
  hash,
  currentHash,
  filledStyle,
  text,
  item,
  disableLsnPlanItem,
  fetchLessonPlanDetailsForEdit,
  handleFormChange,
  disabled,
  userTypeId,
}) {
  const [isDisableItem, setIsDisableItem] = useState(disabled);
  const location = window.location.pathname.split("/");
  const lsnPlanId = location[location.length - 1];

  useEffect(() => {
    setIsDisableItem(disabled);
  }, [disabled]);

  const handleDisableClick = () => {
    disableLsnPlanItem({
      isRemove: isDisableItem,
      lessonPlanId: +lsnPlanId,
      statusId: item.id,
    }).then((e) => {
      fetchLessonPlanDetailsForEdit(lsnPlanId);
    });
    setIsDisableItem((prev) => !prev);
    handleFormChange(`disabled${item.hash}`, !isDisableItem);
  };

  return (
    <div className="lesson_plan_sidebar_item_container">
      <Link
        to={`#${currentHash}`}
        onClick={(e) => {
          onClick(e, currentHash);
        }}
        className={classnames("lesson_plan_sidebar_item", {
          activeNavItem: hash === `#${currentHash}`,
        })}
        style={
          disabled
            ? {
                backgroundColor: "#dfe3e7",
              }
            : {
                ...filledStyle(currentHash),
              }
        }
      >
        {text}
      </Link>
      {userTypeId === 6 && (
        <button
          onClick={handleDisableClick}
          className="lesson_plan_sidebar_item_clear_btn"
        >
          {disabled ? (
            <img src={require("../../../img/edit_bold.svg").default} />
          ) : (
            <img src={require("../../../img/delete_medium.svg").default} />
          )}
        </button>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userTypeId: state.authReducer.typeId,
  };
}

function mapDispathToProps(dispatch) {
  return {
    handleFormChange: (key, value) => dispatch(handleFormChange(key, value)),
    disableLsnPlanItem: ({ isRemove, lessonPlanId, statusId }) =>
      dispatch(
        disableLsnPlanItem({
          isRemove,
          lessonPlanId,
          statusId,
        })
      ),
    fetchLessonPlanDetailsForEdit: (id) =>
      dispatch(fetchLessonPlanDetailsForEdit(id)),
  };
}

export const LessonPlanSidebarItem = connect(
  mapStateToProps,
  mapDispathToProps
)(LessonPlanSidebarItemComponent);
