import * as React from "react";
import "./OpenLayout.css";
import { useState, useEffect } from "react";
import Classes from "./Classes/Classes";
import SuggestedNotes from "./SuggestedNotes/SuggestedNotes";
import Select from "../../../components/Form/Select/Select";
import request from "../../../store/request";
import { connect } from "react-redux";
import { ConfirmLessonPlanFilter } from "./SuggestedNotes/ConfirmLessonPlanFilter";
import { useTranslation } from "react-i18next";
import { SelectWithSearchHOC } from "../../../components";
import {
  changeLessonDescription,
  fetchMethods,
  handleFormChange,
} from "../../../store";

const OpenLayout = ({
  onClose,
  hasSuggest,
  handleCloseFilter,
  methods,
  fetchMethods,
  handleFormChange,
  methodId,
  selectedMethodinFilterName,
}) => {
  const [subject, setSubject] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    request(`/api/subjects?language=${i18n.language}`).then((data) => {
      if (data.success) {
        setSubject(data.result);
      }
    });
  }, []);

  useEffect(() => {
    fetchMethods();
  }, []);

  return (
    <div className="open_layout">
      <div className="selects-container">
        <div className="open-layout-subject-container">
          <Select
            id="subjectName"
            items={subject}
            label={t("Subject")}
            placeholder={t("Subject name")}
            className="class-width"
          />
        </div>
        <div className="select-method-container">
          <label className="select-method-label">
            {t("Select the method")}
          </label>
          <SelectWithSearchHOC
            readOnly={true}
            optionLabelKey="name"
            optionUniqueKey="id"
            options={methods}
            loading={false}
            placeholder={t("Select")}
            inputValue={selectedMethodinFilterName || ""}
            readOnlyStyle={{ top: "50px" }}
            onFocus={(e) => {
              e.target.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
            onOptionClick={(method) => {
              let currentMethodId = method?.id;
              let currentMethodName = method?.name;

              if (methodId === currentMethodId) {
                currentMethodId = null;
                currentMethodName = null;
              }

              handleFormChange("methodId", currentMethodId);
              handleFormChange("selectedMethodinFilterName", currentMethodName);
            }}
            inputClassName="select-method"
          />
        </div>
      </div>

      <Classes />
      {hasSuggest ? (
        <SuggestedNotes onClose={onClose} />
      ) : (
        <ConfirmLessonPlanFilter handleCloseFilter={handleCloseFilter} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    methods: state.lessonPlanReducer.allMethods,
    methodId: state.formReducer.methodId,
    selectedMethodinFilterName: state.formReducer.selectedMethodinFilterName,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchMethods: () => dispatch(fetchMethods()),
    changeLessonDescription: (array) =>
      dispatch(changeLessonDescription(array)),
    handleFormChange: (key, value) => dispatch(handleFormChange(key, value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OpenLayout);

// export default OpenLayout;
