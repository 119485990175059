export const GET_LESSON_PLAN_LINK_REQUEST = "GET_LESSON_PLAN_LINK_REQUEST";
export const GET_LESSON_PLAN_LINK_SUCCESS = "GET_LESSON_PLAN_LINK_SUCCESS";
export const GET_LESSON_PLAN_LINK_FAILURE = "GET_LESSON_PLAN_LINK_FAILURE";

export const GET_LESSON_PLAN_STUDENT_REQUEST =
  "GET_LESSON_PLAN_STUDENT_REQUEST";
export const GET_LESSON_PLAN_STUDENT_SUCCESS =
  "GET_LESSON_PLAN_STUDENT_SUCCESS";
export const GET_LESSON_PLAN_STUDENT_FAILURE =
  "GET_LESSON_PLAN_STUDENT_FAILURE";

export const GET_RATING_DATA_REQUEST = "GET_RATING_DATA_REQUEST";
export const GET_RATING_DATA_SUCCESS = "GET_RATING_DATA_SUCCESS";
export const GET_RATING_DATA_FAILURE = "GET_RATING_DATA_FAILURE";
